<template>
  <div class="page-container">
    <LoadingIcon type="dark" />
  </div>
</template>

<script>

  import LoadingIcon from '@/components/utils/LoadingIcon.vue'

  export default {
    components: {
      LoadingIcon,
    },
    created() {

      // @NOTE: no need to set newRefreshToken and newRefreshTokenExpiry here
      //  since they are being auto-logged in and therefore did not explicity
      //  check the "keep me logged in" option
      const newAuthTokenData = {
        newAuthToken: this.$route.params.authToken,
        newAuthTokenExpiry: this.$route.query.expiresAt || this.$route.query.expires_at || null,
      }

      return this.$store.dispatch('user/UPDATE_AUTH_TOKEN', newAuthTokenData)
        .then(() => {
          return this.$store.dispatch('app/AFTER_LOGIN')
        })
        .then(() => {
          const destination = this.$route.query.destination || '/dashboard'
          this.$router.replace(destination)
        })
        .catch(() => {
          this.$store.dispatch('app/LOGOUT', { force: true })
        })

    },
  }

</script>

<style lang="stylus" scoped>

  .page-container
    @apply w-full
    @apply h-full

    @apply flex
    @apply items-center
    @apply justify-center

</style>
